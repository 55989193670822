// This is the scss entry file
import '../styles/main.scss';

import JustValidate from 'just-validate';
import 'mmenu-light/dist/mmenu-light.css';
import MmenuLight from 'mmenu-light';

function scrollToElementByIdWithOffset(elementId, offset) {
  const element = document.getElementById(elementId);

  if (element) {
    const elementRect = element.getBoundingClientRect();
    const absoluteElementTop = elementRect.top + window.scrollY;
    const targetPosition = absoluteElementTop - offset;

    window.scroll({
      top: targetPosition,
      behavior: 'smooth'
    });
  }
}

window.document.addEventListener('DOMContentLoaded', function () {
  let navHidden = false;
  let previousScrollY = window.scrollY;

  var updateScroll = function(){
    if(window.scrollY > 98){
      if (window.scrollY > previousScrollY && !navHidden) {
        navHidden = true;
        document.body.classList.add('nav-hidden');
        document.body.classList.remove('nav-visible');
      }
    }

    if (window.scrollY < previousScrollY && navHidden) {
      navHidden = false;
      document.body.classList.remove('nav-hidden');
      document.body.classList.add('nav-visible');
    }

    previousScrollY = window.scrollY;
  };

  updateScroll();

  document.addEventListener('scroll', updateScroll);

  const menu = new MmenuLight(
    document.querySelector('#main-nav'),
    "(max-width: 991px)"
  );

  menu.navigation({
    title: ''
  });

  const drawer = menu.offcanvas();

  document.querySelector("a[href='#main-nav']").addEventListener(
    "click",
    (event) => {
      event.preventDefault();
      drawer.open();
    }
  );

  // Base validation rules common to all forms
  const getBaseValidationRules = (nameFieldId, emailFieldId) => {
    return [
      {
        id: nameFieldId,
        rules: [
          {
            rule: 'required',
            errorMessage: 'Este campo es obligatorio',
          },
        ]
      },
      {
        id: emailFieldId,
        rules: [
          {
            rule: 'required',
            errorMessage: 'Este campo es obligatorio',
          },
          {
            rule: 'email',
            errorMessage: 'Por favor ingresa un email válido',
          }
        ]
      }
    ];
  };

  // Setup any form
  const setupAsyncForm = (form, rules, url, onSubmit, onSuccess, onError) => {
    let submitTimeout = false;
    let responseReceived = false;

    const errorMessage = form.querySelector('.error-message');

    const validation = new JustValidate(`#${form.id}`, {
      focusInvalidField: false
    }, rules);

    validation.onFail((fields) => {
      // Scroll to first failing field
      let firstErrorField = null;

      Object.keys(fields).some((key) => {
        if (!fields[key].isValid) {
          firstErrorField = key;
          return true;
        }

        return false;
      });

      scrollToElementByIdWithOffset(firstErrorField.slice(1), 200);
    });

    validation.onSuccess((event) => { 
      event.preventDefault(); // Event is form submit event

      errorMessage.style.display = 'none';

      setTimeout(() => {
        submitTimeout = true;

        if (responseReceived) onResponseReceived();
      }, 1500);

      const onResponseReceived = () => {
        form.querySelectorAll('input[type="text"], input[type="email"], textarea, select').forEach((i) => i.value = '');
        onSuccess();
      };

      const request = new XMLHttpRequest();

      request.open('POST', url);

      const requestError = () => {
        submitTimeout = false;
        responseReceived = false;
        errorMessage.style.display = 'block';

        onError();
      };

      request.onload = () => {
        if (request.status === 200) {
          if (submitTimeout) onResponseReceived();

          responseReceived = true;
        } else {
          requestError();
        }
      };

      request.addEventListener('error', requestError);

      request.send(new FormData(form));
      onSubmit();
    });

    rules.forEach((rule) => {
      validation.addField(rule.id, rule.rules);
    });
  };

  // Setup activity signup form
  const activitySignupForm = document.getElementById('activity-signup-form');

  if (activitySignupForm) {
    const signupsEnabled = activitySignupForm.dataset.signupsEnabled === 'true';
    const activityFormSubmitting = document.getElementById('activity-form-submitting');
    const activityFormSubmitSuccessful = document.getElementById('activity-form-submit-successful');
    const activityFormSubmitError = document.getElementById('activity-form-submit-error');

    const activitySignupUrl = signupsEnabled ? '/submit-activity-signup/' : '/submit-activity-waitlist-signup/';

    const onActivitySignupSubmit = () => {
      activityFormSubmitError.style.display = 'none';
      activityFormSubmitting.style.display = 'block';
      activitySignupForm.style.display = 'none';

      scrollToElementByIdWithOffset('activity-form-submitting', 200);
    };

    const onActivitySignupSuccess = () => {
      activityFormSubmitting.style.display = 'none';
      activityFormSubmitSuccessful.style.display = 'block';
    };

    const onActivitySignupError = () => {
      activityFormSubmitting.style.display = 'none';
      activityFormSubmitError.style.display = 'block';
    };

    const rules = getBaseValidationRules('#activity-form-name', '#activity-form-email');

    if (signupsEnabled) {
      rules.push(
        {
          id: '#residence',
          rules: [
            {
              rule: 'required',
              errorMessage: 'Este campo es obligatorio',
            },
            {
              rule: 'minLength',
              value: 8,
              errorMessage: 'Por favor ingresa al menos 8 caracteres',
            }
          ]
        },
        {
          id: '#source',
          rules: [
            {
              rule: 'required',
              errorMessage: 'Este campo es obligatorio',
            }
          ]
        }
      );
    }

    setupAsyncForm(
      activitySignupForm, rules, activitySignupUrl, onActivitySignupSubmit, onActivitySignupSuccess, onActivitySignupError
    );
  }

  const contactForm = document.getElementById('contact-form');

  if (contactForm) {
    const contactFormSubmitting = document.getElementById('contact-form-submitting');
    const contactFormSubmitSuccessful = document.getElementById('contact-form-submit-successful');
    const contactFormSubmitError = document.getElementById('contact-form-submit-error');

    const onContactFormSubmit = () => {
      contactFormSubmitError.style.display = 'none';
      contactFormSubmitting.style.display = 'block';
      contactForm.style.display = 'none';

      scrollToElementByIdWithOffset('contact-form-submitting', 200);
    };

    const onContactFormSuccess = () => {
      contactFormSubmitting.style.display = 'none';
      contactFormSubmitSuccessful.style.display = 'block';
    };

    const onContactFormError = () => {
      contactFormSubmitting.style.display = 'none';
      contactFormSubmitError.style.display = 'block';
    };

    const rules = getBaseValidationRules('#contact-form-name', '#contact-form-email');

    rules.push(
      {
        id: '#contact-form-message',
        rules: [
          {
            rule: 'required',
            errorMessage: 'Este campo es obligatorio',
          },
          {
            rule: 'minLength',
            value: 30,
            errorMessage: 'Por favor ingresa al menos 30 caracteres',
          }
        ]
      }
    );

    setupAsyncForm(
      contactForm, rules, '/submit-contact/', onContactFormSubmit, onContactFormSuccess, onContactFormError
    );
  }

  const newsletterForm = document.getElementById('newsletter-form');

  if (newsletterForm) {
    const newsletterFormSubmitSuccessful = document.getElementById('newsletter-form-submit-successful');
    const newsletterFormSubmitError = document.getElementById('newsletter-form-submit-error');
    const newsletterFormSubmitButton = newsletterForm.querySelector('button');

    const onNewsletterFormSubmit = () => {
      newsletterFormSubmitError.style.display = 'none';
      newsletterForm.querySelectorAll('input, textarea').forEach((i) => i.disabled = true);
      newsletterFormSubmitButton.disabled = true;
      newsletterFormSubmitButton.classList.add('loading');
    };

    const onNewsletterFormSuccess = () => {
      newsletterForm.style.display = 'none';
      newsletterFormSubmitSuccessful.style.display = 'flex';
    };

    const onNewsletterFormError = () => {
        newsletterFormSubmitError.style.display = 'block';
        newsletterForm.style.display = 'block';
        newsletterFormSubmitButton.disabled = false;
        newsletterFormSubmitButton.classList.remove('loading');
        newsletterForm.querySelectorAll('input, textarea').forEach((i) => i.disabled = false);
    };

    const rules = getBaseValidationRules('#newsletter-form-name', '#newsletter-form-email');

    setupAsyncForm(
      newsletterForm, rules, /submit-newsletter-subscription/, onNewsletterFormSubmit, onNewsletterFormSuccess, onNewsletterFormError
    );
  }
});
